//prettier-ignore
//<!-- prettier-ignore-start -->
import "../../scripts/wdyr"
//<!-- prettier-ignore-end -->
import { CCSWRConfig } from "@apis/swr/config"
import {
	Button,
	Center,
	ChakraProvider,
	Portal,
	Text,
	VStack,
} from "@chakra-ui/react"
import { ErrorBoundary } from "@components/ErrorBoundary"
import { ActionSheetContextProvider } from "@contexts/ActionSheetContext"
import { LayoutContextProvider } from "@contexts/LayoutContext"
import { RegistrationProviderWrapper } from "@contexts/RegistrationProviderWrapper"
import { ToastProvider } from "@contexts/ToastContext"
import "@features/news/news.scss"
import { useForceSSOConnectModal } from "@features/sso/useForceSSOConnectModal"
import { ForceSetSchoolTotalTermsModal } from "@features/term/ForceSetSchoolTotalTermsModal"
import { useInitAnalytics } from "@hooks/useInitAnalytics"
import { useInitialRedirect } from "@hooks/useInitialRedirect"
import { useLoadNewArrivalStatesForBottomTabs } from "@hooks/useLoadNewArrivalStatesForBottomTabs"
import { useOnShake } from "@hooks/useOnShake"
import { usePageViewTracking } from "@hooks/usePageViewTracking"
import { useSetUserPropertiesToLogger } from "@hooks/useSetUserPropertiesToLogger"
import { useSyncCurrentCourseWithQueryString } from "@hooks/useSyncCurrentCourseWithQueryString"
import { useUpdateUrlQueryString } from "@hooks/useUpdateUrlQueryString"
import { store } from "@stores/index"
import "@styles/globals.scss"
import "@styles/googleTranslationOverride.css"
import { customTheme } from "@styles/theme"
import "focus-visible/dist/focus-visible"
import type { AppProps } from "next/app"
import { useRouter } from "next/router"
import React, { FC } from "react"
import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import smoothscroll from "smoothscroll-polyfill"

if (typeof window !== "undefined") {
	smoothscroll.polyfill()
	// window.__forceSmoothScrollPolyfill__ = true
}

const persistor = persistStore(store)

type RedirectProps = {
	children: React.ReactNode
}
const Redirect: FC<RedirectProps> = ({ children }) => {
	const { isCheckAuthDone, errorMessage } = useInitialRedirect()
	const { Modal: ForceGoogleAuthModal } = useForceSSOConnectModal()
	const router = useRouter()
	return (
		<>
			{/* 別に見せなくていい気がしてきた */}
			{/* {!isCheckAuthDone && (
				<Portal>
					<Center
						position={"fixed"}
						top={0}
						left={0}
						w="100vw"
						h="100vh"
						bg="rgba(0,0,0,0.7)"
						zIndex={10}
					>
						<VStack>
							<TextWithFurigana furigana="にんしょうちゅうです" color="#fff">
								認証中です...
							</TextWithFurigana>
							<Spinner color="#fff" />
						</VStack>
					</Center>
				</Portal>
			)} */}
			{!!errorMessage && (
				<Portal>
					<Center
						position={"fixed"}
						top={0}
						left={0}
						w="100vw"
						h="100vh"
						bg="#fff"
						zIndex={10}
					>
						<VStack>
							<Text>{errorMessage}</Text>
							<Center w="100%">
								<Button variant="link" onClick={router.reload}>
									ページをリロードする
								</Button>
							</Center>
						</VStack>
					</Center>
				</Portal>
			)}
			{ForceGoogleAuthModal}
			<ForceSetSchoolTotalTermsModal />
			{children}
		</>
	)
}

type UrlSyncProps = {
	children: React.ReactNode
}

const UrlSync: FC<UrlSyncProps> = ({ children }) => {
	useSyncCurrentCourseWithQueryString()
	useUpdateUrlQueryString()
	return <>{children}</>
}

type HooksProps = {
	children: React.ReactNode
}
const Hooks: FC<HooksProps> = ({ children }) => {
	useSetUserPropertiesToLogger()
	usePageViewTracking()
	useLoadNewArrivalStatesForBottomTabs()
	useOnShake({
		onShake: () => {
			console.log("shake" + String(new Date()))
		},
	})

	return <>{children}</>
}

function App({ Component, pageProps }: AppProps) {
	useInitAnalytics()
	return (
		<ChakraProvider theme={customTheme} resetCSS={true}>
			<ErrorBoundary>
				<Provider store={store}>
					<RegistrationProviderWrapper>
						<PersistGate loading={null} persistor={persistor}>
							<ActionSheetContextProvider>
								<ToastProvider>
									<CCSWRConfig>
										<Redirect>
											<UrlSync>
												{/* <meta
													name="viewport"
													content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
												/> */}
												<Hooks>
													<LayoutContextProvider>
														<Component {...pageProps} />
													</LayoutContextProvider>
												</Hooks>
											</UrlSync>
										</Redirect>
									</CCSWRConfig>
								</ToastProvider>
							</ActionSheetContextProvider>
						</PersistGate>
					</RegistrationProviderWrapper>
				</Provider>
			</ErrorBoundary>
		</ChakraProvider>
	)
}

export default App
